.about-me {
  padding: 70px 0;
}

.about-me__content {
  width: calc(100% - 2 * 70px);
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.about-me__text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  'img title'
  'img parag'
  'img parag';
  column-gap: 20px;
  margin: 70px 0 0;
}

.about-me__heading {
  grid-area: title;
  margin: 0;
  padding: 20px 10px;
  font-size: 38px;
  font-weight: 400;
}

.about-me__paragraph {
  grid-area: parag;
  margin: 0;
  padding: 10px;
  font-size: 18px;
}
/*text-indent: 15px;*/

.about-me__img {
  grid-area: img;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 700px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: var(--text-grey) 8px 8px 10px;
}

.about-me__text-r {
  margin: 100px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  'title img'
  'parag img';
  column-gap: 20px;
}

@media screen and (max-width: 1024px) {
  .about-me {
    padding: 50px 0;
  }
  .about-me__content {
    width: calc(100% - 2 * 40px);
  }
  .about-me__heading {
    font-size: 26px;
  }
  .about-me__text {
    margin: 50px 0 0;
  }
  .about-me__img {
    max-height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about-me__content {
    width: calc(100% - 2 * 20px);
  }
  .about-me__text {
    margin: 30px 0 0;
    grid-template-columns: 1fr;
    grid-template-areas:
    'img'
    'title'
    'parag';
    gap: 10px;
  }
  .about-me__heading {
    font-size: 24px;
    padding: 12px 0;
  }
  .about-me__paragraph {
    font-size: 14px;
    padding: 5px 0;
  }
  .about-me__img {
    max-height: 400px;
    max-width: 400px;
    justify-self: center;
  }
}

@media screen and (max-width: 425px) {
  .about-me {
    padding: 30px 0;
  }
  .about-me__heading {
    font-size: 22px;
    padding: 10px 0;
  }
  .about-me__text {
    margin: 20px 0 0;
  }
  .about-me__img {
    max-height: 310px;
  }
}