.header {
  padding: 10px 0;
  background-color: var(--light-grey);
}

.header__content {
  width: calc(100% - 2 * 70px);
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button {
  text-align: center;
  cursor: pointer;
  transition: opacity .3s linear;
}
.button:hover {
  opacity: 0.8;
  color: var(--green);
}

.header__button {
  font-size: 16px;
  line-height: 22px;
  padding: 5px;
  margin-left: 5px;
  color: var(--black);
  border: none;
  background-color: transparent;
  text-decoration: none;
}


@media screen and (max-width: 768px) {
  .header__content {
    width: calc(100% - 2 * 20px);
  }
  .header__button {
    font-size: 14px;
    padding: 0 5px ;
  }
}

@media screen and (max-width: 425px) {
  .header__content {
    width: calc(100% - 2 * 10px);
  }
  .header__button {
    font-size: 12px;
    margin: 0 5px;
    padding: 0;
  }
}