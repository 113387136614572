.diploma {
  padding: 70px 0;
}

.diploma__content {
  width: calc(100% - 2 * 70px);
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.diploma__heading {
  margin: 0 auto 70px;
  padding: 0;
  text-align: center;
  font-size: 38px;
  font-weight: 400;
}

.diploma__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 40px;
}

.diploma__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: .5s;
}

.diploma__img {
  max-width: 270px;
  height: 200px;
  border: 10px solid var(--black);
  overflow: hidden;
}


@media screen and (max-width: 1024px) {
  .diploma {
    padding: 50px 0;
  }
  .diploma__heading {
    font-size: 26px;
    margin: 0 auto 50px;
  }
  .diploma__img {
    width: 160px;
    height: 130px;
  }
}

@media screen and (max-width: 768px) {
  .diploma {
    padding: 20px 0 50px;
  }
  .diploma__content {
    width: calc(100% - 2* 20px);
  }
  .diploma__heading {
    font-size: 24px;
    margin: 0 auto 50px;
  }
  .diploma__list {
    gap: 30px;
  }
  .diploma__img {
    width: 140px;
    height: 100px;
  }
}

@media screen and (max-width: 425px) {
  .diploma {
    padding: 30px 0;
  }
  .diploma__heading {
    font-size: 22px;
    margin:  0 auto 30px;
  }
  .diploma__list {
    gap: 20px;
  }
}
