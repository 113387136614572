.myStoryA {
  padding: 0;
  background-color: var(--light-grey);
}

.myStoryA__text {
  display: flex;
  flex-direction: column;
}

.myStoryA__m {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.myStoryA__photo {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 40px 0;
  align-items: center;
  justify-content: space-between;
}

.myStoryA__img {
  max-height: 400px;
  box-sizing: border-box;
  border-radius: 10px;
  object-fit: cover;
  align-items: center;
  align-self: center;
  box-shadow: var(--text-grey) 8px 8px 10px;
}

@media screen and (max-width: 870px) {
  .myStoryA__img {
    max-height: 355px;
  }
  .myStoryA__photo {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
}

@media screen and (max-width: 500px) {
  .myStoryA__photo {
    padding: 0;
  }
  .myStoryA__img {
    max-width: 200px;
    max-height: 200px;
  }
}