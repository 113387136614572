@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

.body {
  margin: 0 auto;
  min-height: 100vh;
  font-family: "Inter", "Helvetica Neue", Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  font-size: 18px;
  font-weight: 400;
  background-color: var(--white);
  color: var(--black);
  /*min-width: 0*/;
  scroll-behavior: smooth;
}

:root {
  scroll-behavior: smooth;
  --white: #FFFFFF;
  --black: #2a2c2f;
  --green: #2f562f;
  --light-grey: #F2F2F2;
  --text-grey: #A0A0A0;
}
