.myStory {
  padding: 70px 0;
}

.myStory__content {
  width: calc(100% - 2 * 70px);
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.lemon {
  background-image: url(../../images/mystory/lemon.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  position: absolute;
  height: 568px;
  width: 600px;
  top: -135px;
  right: 0;
  z-index: -1;
}

.myStory__heading {
  margin: 40px auto 60px;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 38px;
  color: var(--black);
}

.myStory__img {
  max-height: 400px;
  box-sizing: border-box;
  border-radius: 10px;
  object-fit: cover;
  align-items: center;
  align-self: center;
  box-shadow: var(--text-grey) 8px 8px 10px;
}

.myStory__blocks {
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.myStory__text {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0;
}

.myStory__parag {
  padding: 20px;
  margin: 0;
  line-height: 20px;
}

@media screen and (max-width: 1024px) {
  .myStory__content {
    width: calc(100% - 2 * 40px);
  }
}

@media screen and (max-width: 768px) {
  .myStory {
    padding: 40px 0;
  }
  .myStory__heading {
    margin: 15px auto;
    font-size: 30px;
  }
  .lemon {
    width: 690px;
    top: -150px;
  }
  .myStory__blocks {
    flex-direction: column;
    column-gap: 20px;
  }
  .myStory__text {
    max-width: 100%;
  }
  .myStory__parag {
    font-size: 14px;
    padding: 10px 0;
    line-height: 18px;
  }
  .myStory__img {
    margin: 0;
    max-height: 350px;
    max-width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .myStory {
    padding: 35px 0;
  }
  .myStory__content {
    width: calc(100% - 2 * 20px);
  }
  .myStory__img {
    max-width: 200px;
  }
  .myStory__heading {
    font-size: 26px;
  }
}