.element {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'element__foto'
    'element__title';
  border-radius: 10px;
  color: white;
}

.element__foto {
  width: 100%;
  height: 188px;
  object-fit: cover;
  grid-area: element__foto;
  border-radius: 5px;
  cursor: pointer;
  border: 8px solid var(--black);
  box-shadow: var(--text-grey) 8px 8px 10px;
  border: 8px solid var(--black);
}

.element__title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 900;
  margin: 25px auto 0px 21px;
  grid-area: element__title;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 220px;
  color: white;
}

.elements__card {
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .element__foto {
    height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .element__foto {
    height: 130px;
  }
}

@media screen and (max-width: 431px) {
  .element__foto {
    height: 92px;
    max-width: 163px;
  }
}