.consultation {
  padding: 70px 0;
  background-color: var(--white);
}

.consultation__content {
  width: calc(100% - 2 * 70px);
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  
}

.consultation__heading {
  margin: 0 auto 70px;
  padding: 0;
  text-align: center;
  font-size: 38px;
  font-weight: 400;
}

.consultation__colomn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border: 2px solid var(--text-grey);
  border-radius: 50px;
}

.consultation__text {
  padding: 0px 15px;
}

.consultation__caption {
  margin: 15px 0 0;
  padding: 0;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
}

.consultation__paragraph {
  margin: 30px 0 0;
  padding: 0;
  font-size: 18px;
}

.consultation__list {
  margin: 30px 0 0;
  padding: 0 0 0 20px;
  font-size: 18px;
}

.consultation__item { 
  padding: 0 0 0 10px
}

.consultation__price {
  margin: 30px 0 0;
  font-size: 22px;
  font-weight: 600;
}

.consultation__link {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 15px 30px;
  padding: 20px;
  align-self: center;
  font-size: 26px;
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--green);
  color: var(--light-grey);
  border-radius: 10px;
}

.link {
  text-decoration: none;
  transition: opacity .3s linear;
  cursor: pointer;
  list-style: none;
  color: var(--white);
}
.link:hover { opacity: 0.7 }

@media screen and (max-width: 1080px) {
  .consultation {
    padding: 50px 0;
  }
  .consultation__content {
    width: calc(100% - 2 * 40px);
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }
  .consultation__heading {
    font-size: 26px;
    margin: 0 auto 50px;
  }
  .consultation__caption {
    font-size: 22px;
  }
  .consultation__price {
    font-size: 18px;
  }
  .consultation__link {
    padding: 15px;
    font-size: 22px;
  }
  .consultation__colomn {
    padding: 15px;
  }
  .consultation__text {
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .consultation__content {
    flex-direction: column;
    width: calc(100% - 2 * 20px);
  }
  .consultation__caption {
    font-size: 20px;
  }
  .consultation__colomn {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .consultation__list {
    padding: 0 0 0 15px;
  }
  .consultation__text {
    padding: 0 20px;
  }
  .consultation__item {
    font-size: 14px;
  }
  .consultation__price {
    font-size: 16px;
  }
  .consultation__link {
    padding: 10px;
    font-size: 18px;
    margin: 30px 15px 30px;
  }
  .consultation__heading {
    font-size: 24px;
  }
  .consultation__paragraph {
    font-size: 14px;
    margin: 20px 0 0;
  }
}

@media screen and (max-width: 425px) {
  .consultation {
    padding: 30px 0;
  }
  .consultation__heading {
    margin: 0 auto 30px;
  }
}