.not-found {
  position: relative;
  width: 100%;
  color: var(--black);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../images/mystory/lemon.jpg") center / 100% no-repeat;
}

.not-found__title {
  margin: 0;
  font-size: 140px;
  line-height: 169px;
}

.not-found__pharagraph {
  margin: 5px 0 0;
  font-size: 22px;
  line-height: 19px;
}

.not-found__link {
  position: absolute;
  bottom: 30px;
  font-size: 22px;
  line-height: 16px;
  color: var(--black);
}

@media screen and (max-width: 520px) {
  .not-found__title {
    font-size: 80px;
    line-height: 96px;
  }
  .not-found__pharagraph {
    font-size: 12px;
    line-height: 14px;
  }
  .not-found__link {
    font-size: 12px;
    line-height: 14px;
  }
}
