.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0%, 0%, 0%, 50%);
}

.popup_zoom {
  background-color: rgb(0%, 0%, 0%, 90%);
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.popup__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup__figure {
  position: relative;
  margin: 0;
  padding: 0;
}

.popup__close-button {
  position: absolute;
  top: -41px;
  right: -41px;
  width: 32px;
  height: 32px;
  padding: 0;
  background-image: url(../../images/Close-Icon.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 32px 32px;
  border-style: none;
  border-radius: 2px;
  transition: opacity .3s ease;
}
.popup__close-button:hover {
  opacity: .6;
  cursor: pointer;
}

.popup__image {
  max-width: 75vw;
  max-height: 75vh;
}

.popup__figcaption {
  margin-top: 10px;
  /*max-width: 290px;*/
  max-height: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  align-items: flex-start;
  color: white;
}


@media all and (max-width: 540px) {
  .popup__close-button {
    top: -36px;
    right: 0px;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
  }
}
