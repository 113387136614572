.add__content {
  max-width: 1440px;
  margin: 0 auto;
}

.add__img {
  width: 100%;
  height: 125px;
  object-fit: cover;
  margin: 0;
  display: block;
}

/*
  background-image: url(../../images/eda.JPG);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: scroll;*/