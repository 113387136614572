.quote {
 padding: 70px 0 0;
 width: calc(100% - 2 * 70px);
 max-width: 1280px;
 margin: 0 auto;
}

.quote__paragraph {
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-family: Arial, sans-serif;
  padding: 0 0 0 20px;
  border-left: 4px solid var(--green);
}

@media screen and (max-width: 1024px) {
  .quote {
    width: calc(100% - 2 * 40px);
    padding: 50px 0 0;
  }
  .quote__paragraph {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .quote__paragraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .quote {
    width: calc(100% - 2 * 20px);
    padding: 30px 0 0;
  }
  .quote__paragraph {
    font-size: 14px;
  }
}