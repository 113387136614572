.promo {
  background-color: var(--light-grey);
}

.promo__content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.promo__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.promo__title {
  margin: 0;
  color: var(--black);
  align-items: center;
  font-weight: 400;
  font-size: 52px;
}

.promo__paragraph {
  margin: 40px 0 0 0;
  padding: 0;
  font-size: 25px;
}
.promo__paragraph:last-of-type {
  margin: 15px 0 0 0;
  padding: 0;
  font-size: 25px;
}

.promo__link {
  background-color: var(--green);
  border-radius: 5px;
  margin: 40px 0;
  padding: 10px;
  text-align: center;
  width: 130px;
}

.promo__img {
  height: auto;
  max-height: 700px;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

@media screen and (max-width: 1024px) {
  .promo__img {
    max-width: 500px;
  }
  .promo__title {
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  .promo__content {
    flex-direction: column;
  }
  .promo__title {
    font-size: 30px;
    align-self: center;
  }
  .promo__info {
    padding: 15px 0 0;
    align-items: center;
  }
  .promo__link {
    margin: 15px 0 10px;
  }
  .promo__paragraph {
    font-size: 16px;
    margin: 15px 0 0;
  }
  .promo__paragraph:last-of-type {
    font-size: 16px;
    margin: 15px 0 0;
  }
  .promo__img {
    max-width: 765px;
    max-height: 450px;
  }
}

@media screen and (max-width: 425px) {
  .promo__title {
    font-size: 26px;
  }
  .promo__info {
    padding: 10px 0 0;
  }
  .promo__paragraph {
    margin: 10px 0 0;
  }
  .promo__paragraph:last-of-type {
    margin: 10px 0 0;
  }
  .promo__link {
    padding: 7px;
    margin: 10px 0 6px;
  }
}