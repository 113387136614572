.warning {
  padding: 70px 0;
  background-color: var(--green);
  margin: 0;
}

.warning__content {
  width: calc(100% - 2 * 70px);
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.warning__text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  'title img'
  'parag img';
  column-gap: 40px;
  row-gap: 20px;
  margin: 0;
}

.warning__heading {
  grid-area: title;
  display: flex;
  flex-direction: column;
  color: var(--white);
  font-size: 34px;
  font-weight: 400;
  margin: 0;
  padding: 10px 0;
}

.warning__paragraph {
  grid-area: parag;
  margin: 0;
  padding: 10px 0;
  font-size: 18px;
  color: var(--white);
}
/*text-indent: 15px;*/

.warning__img {
  grid-area: img;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: var(--black) 8px 8px 10px;
}

@media screen and (max-width: 1024px) {
  .warning {
    padding: 50px 0px;
  }
  .warning__content {
    width: calc(100% - 2 * 40px);
  }
  .warning__heading {
    font-size: 26px;
  }
}

@media screen and (max-width: 768px) {
  .warning {
    padding: 50px 0;
  }
  .warning__content {
    width: calc(100% - 2 * 20px);
  }
  .warning__text {
    grid-template-columns: 1fr;
    grid-template-areas: 
    "img"
    "title"
    "parag";
  }
  .warning__img {
    max-height: 350px;
  }
  .warning__heading {
    font-size: 24px;
  }
  .warning__paragraph {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .warning__heading {
    font-size: 22px;
  }
}