.galleryy {
  max-width: 1280px;
  max-height: 1440px;
  width: calc(100% - 2 * 70px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr) 2fr 200px;
  grid-template-rows: repeat(6, 1fr);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  gap: 10px;
  padding: 60px 0;
}

.gallery__item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

.gallery__image {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.gallery__item:nth-child(7) {
  grid-column: 6/-1;
  grid-row: 1/-1;
}

.gallery__item:nth-child(5) {
grid-column: 4/6;
grid-row: 4/-1;
}

.gallery__item:nth-child(1) {
grid-column: span 2;
  grid-row: span 2;
}

.gallery__item:nth-child(3) {
grid-column: span 2;
  grid-row: span 2;
}

.gallery__item:nth-child(2) {
grid-column: 3/6;
  grid-row: span 3;
}

.gallery__item:nth-child(4) {
grid-column: 1/4;
  grid-row: span 2;
}

.gallery__item:nth-child(6) {
grid-column: 3;
  grid-row: 4;
}

@media all and (max-width: 1024px) {
  .galleryy {
    width: calc(100% - 2 * 40px);
  }
}

@media all and (max-width: 520px) {
  .galleryy {
    width: calc(100% - 2 * 20px);
    grid-template-columns: repeat(4, 1fr) 2fr 80px;
    gap: 5px;
  }
}

@media all and (max-width: 425px) {
  .galleryy {
    width: calc(100% - 2 * 20px);
    padding: 30px 0;
  }
}